import React, { useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import toast, { Toaster } from 'react-hot-toast';
import { TextField, Button } from '@honehealth/components';
import mixpanel from 'mixpanel-browser';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import DarklyIdentify from '../components/Darkly';
import NavBar from '../components/Navbar';
import CTAButton from '../components/CTAButton';
import { useFlag } from '../utils/useFlag';

import { myContext } from '../components/utils/ContextProvider';
import {
  formValidation,
  storeUTMParams,
  urlDataDistributor,
  validateEmail,
} from '../components/utils/Utils';
import FeatureAd from '../components/FeatureAd';

const NextButton = (props) => {
  const flags = useFlags();

  return (
    <CTAButton
      {...props}
    >
      {flags.checkoutStepOneCta || 'CONTINUE'}
    </CTAButton>
  );
}

const SubHeadline = () => {
  return (
    <h4 key="subheadline-default" className="text-center self-center hidden md:flex">
      You're just 5 minutes away from honing in on the best version of
      yourself. Enter your
      email below to get started.
    </h4>
  );
};

const IndexPage = () => {
  mixpanel.init(process.env.MIXPANEL_KEY);
  const globalContext = useContext(myContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmail] = useState('');
  const flags = useFlags();

  let location = typeof window !== 'undefined' ? window.location.href : '';
  const priceValue = urlDataDistributor(location, 'index');

  const validate = (...fields) => {
    // If the firstName and lastName collection are on validate the fields are filled out.
    if (validateEmail(emailAddress)) {
      globalContext.changeFirst(firstName);
      globalContext.changeLast(lastName);
      globalContext.changeEmail(emailAddress);
      mixpanel.track('Started Kit Purchase', {
      branch: process.env.GATSBY_BRANCH,
        'First Name': firstName,
        'Last Name': lastName,
        Email: emailAddress,
      });
      navigate('/validate');
    } else toast('Please enter a valid email address');
  };

  useEffect(() => {
    const queryString = window.location.search;
    storeUTMParams(queryString);
    mixpanel.track('Used Checkout 2.XX');
  }, []);

  return (
    <>
      <DarklyIdentify>
      <NavBar />
      <div className="min-h-screen flex items-center justify-center">
        <title>Buy Assessment Page</title>
        <div className="bg-white grid h-full w-full p-2 sm:grid-cols-1 gap-1 md:grid-cols-2 md:p-8 md:w-2/3 xl:w-311.23 rounded shadow-2xl">
          <h1 className="text-2xl p-1 text-center font-bold md:order-1">
            Feel Like Your Younger Self Again
          </h1>
          <form className="space-y-3 px-2 md:pt-4 sm:p-0 order-last md:order-last">
            <SubHeadline />
            <div>
              <TextField
                id="email"
                label="Email"
                type="email"
                placeholder="your@email.com"
                value={emailAddress}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="">
              <NextButton
                onClick={(e) => {
                  e.preventDefault();
                  validate(firstName, lastName, emailAddress);
                }}
              />
                <span className="font-inter text-sm">
                  By clicking this button, I acknowledge that I have read,
                  understand, and agree to the
                  <a
                    href="https://honehealth.com/terms"
                    className="hover:text-red-600 text-darkGrey text-sm
      "
                  >
                    {' '}
                    Terms of Use
                  </a>{' '}
                  and
                  <a
                    href="https://honehealth.com/privacy"
                    className="hover:text-red-600 text-darkGrey text-sm
      "
                  >
                    {' '}
                    Privacy Policy
                  </a>
                  {priceValue.disclaimer}.
                </span>
              </div>
              <Toaster
                containerStyle={{ position: 'absolute' }}
                toastOptions={{
                  className: '',
                  style: {
                    color: 'rgba(252, 252, 250)',
                    backgroundColor: 'rgba(239, 50, 25)',
                    fontFamily: 'inter',
                  },
                }}
              />
              <div></div>
            </form>
            <FeatureAd priceObj={priceValue} />
          </div>
        </div>
      </DarklyIdentify>
    </>
  );
};

export default IndexPage;
