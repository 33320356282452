import { useFlags } from 'gatsby-plugin-launchdarkly';

export const useFlag = (flag) => {
  const flags = useFlags();

  const searchString = typeof window !== 'undefined' ? window.location.search : '';
  const params = new URLSearchParams(searchString);

  return params.get(flag) || flags[flag];
}
