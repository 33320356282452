import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const FeatureImage = () => (
  <StaticImage
    key="open-with-video"
    alt="assessment box"
    src="../images/open-with-video.png"
    className="w-full h-full"
    objectFit="contain"
  />
);

export default FeatureImage;
