import { UAParser } from 'ua-parser-js';
import { useLDClient } from 'gatsby-plugin-launchdarkly';
import { useEffect } from 'react';

/**
* This component is responsible for identifying the device and setting the context in Darkly.
*
* This should be placed only on landing pages so its set when the user first lands on a page.
*/
const DarklyIdentify = ({ children }) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient) {
      const ua = new UAParser(navigator.userAgent);
      const device = ua.getDevice();
      const os = ua.getOS();
      const browser = ua.getBrowser();

      const context = ldClient.getContext();

      const query = new URLSearchParams(window.location.search);

      ldClient.identify({
        kind: 'multi',
        device: {
          // We use the `ld_ident` if it is provided as it means the ld context/funnel was initialized outside of this checkout.
          key: query.get('ld_ident') || context.key,
          type: device.type || 'unknown',
          operating_system: os.name + ' ' + os.version,
          browser: browser.name + ' ' + browser.version,
        },
        user: {
          key: query.get('ld_ident') || context.key,
          anonymous: true,
        },
      }, undefined, () => {
        ldClient.flush();
        ldClient.variation('checkout-timer');
      });
    }
  }, [ldClient]);

  return children;
}

export default DarklyIdentify;
