import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { urlBasedPageData } from './utils/Data';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import FeatureImage from './FeatureImage';
import { useFlag } from '../utils/useFlag';

const SubHeadline = ({ subTitle }) => {
  const flags = useFlags();
  const subheadlineVariant = useFlag('checkoutSubheadlineVariant');

  switch (subheadlineVariant) {
    case 'priority-access':
      return (
        <h1 className={subTitle.style} key="subheadline-priority-access-mobile">
          Order today and get free <br /> Priority Doctor Access.
        </h1>
      );
    case 'energy':
      return (
        <h1 className={subTitle.style} key="subheadline-energy-mobile">
          Start getting your energy <br /> levels back today.
        </h1>
      );
    case 'experienced-doctor':
      return (
        <h1 className={subTitle.style} key="subheadline-privacy-mobile">
          Get a personalized consultation with an experienced Hone doctor.
        </h1>
      );
    case 'test-doctor':
      return (
        <h1 className={subTitle.style} key="subheadline-privacy-mobile">
          Order your at-home test to get a free consultation with an experienced Hone doctor.
        </h1>
      );
    case 'privacy':
      return (
        <h1 className={subTitle.style} key="subheadline-privacy-mobile">
          Get a consultation without leaving the comfort of your home.
        </h1>
      );
    default:
      return (
        <h1 className={subTitle.style}>{subTitle.text}</h1>
      );
  }
};

export default function FeatureAd({ priceObj = urlBasedPageData.store.index }) {
  const flags = useFlags();
  mixpanel.init(process.env.MIXPANEL_KEY);

  let { price, tracker, discount, image, subTitle, origPrice } = priceObj;

  const originalPrice = (flags.checkoutOriginalPrice || '60') === '60' ? 60 : 150;

  useEffect(() => {
    mixpanel.track(tracker, {
      branch: process.env.GATSBY_BRANCH,
    });
  }, []);

  return (
    <div className="flex items-center p-2 pb-0 box-content order-2 md:row-span-2">
      <div className="items-center m-auto md:border md:border-black md:box-content md:box-sizing md:h-108">
        <div className="grid grid-cols-3 md:pt-4 pb-0 gap-0 px-1 justify-items-center w-full">
          <div className="flex items-center col-span-3  rounded w-3/4">
            <h4 className="m-auto hidden xl:text-lg font-bold md:text-base md:flex">
              YOU'RE SAVING ${~~(originalPrice - price)}
            </h4>
          </div>
          <SubHeadline subTitle={subTitle} />
          <div className="lg:align-right md:flex xl:col-start-1 col-span-3 xl:col-span-1 mb-[-1px]">
            <FeatureImage />
          </div>
          <div className="grid grid-cols-2 text-center pt-10 align-bottom w-full col-span-3 xl:col-span-2 xl:col-start-2">
            <div className="p-1">
              <h2 className="text-xl font-bold text-black md:text-center md:text-lg">
                Original Price
              </h2>
              <h2 className="text-2xl xl:text-3xl font-bold text-red-600 line-through md:text-center">
                ${originalPrice}
              </h2>
            </div>
            <div className="p-1">
              <h2 className="text-xl font-bold text-black md:text-center md:text-lg">
                Special Offer
              </h2>
              <h2 className=" text-2xl xl:text-3xl font-bold text-black md:text-center">
                ${price}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
